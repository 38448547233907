import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import styles
import "../styles/maesh.scss"

// Images
import maesh_logo from "../images/logos/traefik-mesh-logo.svg"
import intro__left_illustration from "../images/maesh/left-graphic.svg"
import intro__right_illustration from "../images/maesh/right-graphic.svg"
import details__security_icon from "../images/maesh/features/security-icon.svg"
import details__communications_icon from "../images/maesh/features/communications-icon.svg"
import details__market_icon from "../images/maesh/features/market-icon.svg"
import details__cluster_icon from "../images/maesh/features/cluster-icon.svg"
import features__open_source from "../images/maesh/features-2/open-source-icon.svg"
import features__observability from "../images/maesh/features-2/observability-icon.svg"
import features__protocols from "../images/maesh/features-2/protocols-icon.svg"
import features__traffic from "../images/maesh/features-2/traffic-icon.svg"
import features__security from "../images/maesh/features-2/security-icon.svg"
import features__installation from "../images/maesh/features-2/installation-icon.svg"
import integration__kubernetes from "../images/maesh/integrations/kubernetes-logo.svg"
import cta__path from "../images/maesh/next-steps/path.svg"
import cta__documentation from "../images/maesh/next-steps/dossier-2.svg"
import cta__community from "../images/maesh/next-steps/speech-bubbles-2.svg"

const scrollTo = (id: string) => {
  let position = document.getElementById(id)?.offsetTop || 0
  window.scroll({
    top: position,
    left: 0,
    behavior: "smooth",
  })
}

interface Props {
  data: any
  location: any
}

const MaeshPage = ({ data, location }: Props) => {
  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="maesh">
        {/* Intro Section */}
        <section className="maesh-intro">
          <div className="wrapper-1200">
            <div className="maesh-intro__logo">
              <h1>
                <img
                  alt="Traefik Mesh - Simpler Service Mesh for Kubernetes"
                  src={maesh_logo}
                />
              </h1>
            </div>

            <h2>Simpler Service Mesh</h2>
            <p>
              Traefik Mesh is a straight-forward, easy to configure, and
              non-invasive service mesh that allows visibility and management of
              the traffic flows inside any Kubernetes cluster.
            </p>
            <div className="links">
              <a
                className="button--purple button--extra-padding"
                onClick={() => scrollTo("maesh-cta")}
              >
                Get Started
              </a>
              <a
                className="link--with-arrow"
                href="https://community.traefik.io/c/traefik-mesh/15"
              >
                Join the Community
              </a>
            </div>
          </div>

          <div className="maesh-intro__images">
            <img className="img-left" src={intro__left_illustration} />
            <img className="img-right" src={intro__right_illustration} />
          </div>
        </section>

        {/* Details Section */}
        <section className="maesh-details">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>
              Route and Monitor all your service-to-service communications with
              ease
            </h2>
            <div className="links">
              <a
                className="link--with-arrow maesh-details__community"
                href="https://traefik.io/blog/announcing-maesh-a-lightweight-and-simpler-service-mesh-made-by-the-traefik-team-cb866edc6f29/"
              >
                Learn more
              </a>
            </div>
            <div className="maesh-details__features">
              <div className="feature">
                <img
                  alt="Increase cluster security with Traefik Mesh"
                  src={details__security_icon}
                />
                <h3>Improve Security</h3>
                <p>
                  By improving monitoring, logging, and visibility, as well as
                  implementing access controls. Allows administrators to
                  increase the security of their clusters easily and quickly.
                </p>
              </div>
              <div className="feature">
                <img
                  alt="Monitor and trace applications communications in Kubernetes with Traefik Mesh"
                  src={details__communications_icon}
                />
                <h3>Optimize Communications</h3>
                <p>
                  By being able to monitor and trace how applications
                  communicate in your Kubernetes cluster, administrators are
                  able to optimize internal communications, and improve
                  application performance.
                </p>
              </div>
              <div className="feature">
                <img
                  alt="Traefik Mesh - Service Mesh easy to implement"
                  src={details__market_icon}
                />
                <h3>Go to Market Faster</h3>
                <p>
                  Reducing the time to learn, install, and configure makes it
                  easier to implement, and to provide value for the time
                  actually spent implementing. Administrators can focus on their
                  business applications.
                </p>
              </div>
              <div className="feature">
                <img
                  alt="Traefik Mesh - Open source service mesh opt-in by design"
                  src={details__cluster_icon}
                />
                <h3>Future Proof Your Cluster</h3>
                <p>
                  Being open source means that there is no vendor lock-in, as
                  Traefik Mesh is opt-in by design. Being able to see where
                  resources are underutilized, or where services are overloaded
                  quickly and easily can save money by allowing resources to be
                  allocated properly.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Architecture */}
        <section className="maesh-architecture">
          <div className="cubes-bg">
            <div className="wrapper-1200">
              <hr className="hr-tiny" />
              <h2>Traefik Mesh Architecture</h2>
              <p className="sub-title">
                Built on top of the leading Traefik Proxy for routing.
              </p>
              <div className="comparison">
                <div className="before">
                  <h3>Before Traefik Mesh</h3>
                  <p>
                    No control on the internal communication, and no tracing,
                    monitoring possible.
                  </p>
                  <div className="maesh-diagram-svg">
                    <StaticImage
                      src="../images/maesh/architecture/before-maesh-graphic@3x.png"
                      alt="Before Traefik Mesh"
                      width={650}
                      placeholder="blurred"
                    />
                  </div>
                </div>
                <div className="after">
                  <h3>After Traefik Mesh</h3>
                  <p>Internal communication fully controlled and monitored.</p>
                  <div className="maesh-diagram-svg">
                    <StaticImage
                      src="../images/maesh/architecture/after-maesh-graphic@3x.png"
                      alt="After Traefik Mesh"
                      width={650}
                      placeholder="blurred"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features */}
        <section className="maesh-features">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Traefik Mesh Features</h2>
            <p className="sub-title">
              Able to provide all the features that Traefik offers.
            </p>
            <div className="features">
              <div className="feature">
                <img
                  src={features__open_source}
                  alt="Traefik Mesh - Open Source service mesh"
                />
                <h3>Open Source</h3>
                <ul>
                  <li>
                    <p>Driven by the community.</p>
                  </li>
                  <li>
                    <p>Avoid any form of lock-in.</p>
                  </li>
                </ul>
              </div>
              <div className="feature">
                <img
                  src={features__observability}
                  alt="Traefik Mesh - OpenTracing support and pre-installed with Prometheus and Grafana"
                />
                <h3>Observability</h3>
                <ul>
                  <li>
                    <p>
                      <strong>OpenTracing Support:</strong> Works with all
                      compliant OpenTracing systems.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Out-of-the-box Metrics:</strong> Pre-installed
                      with Prometheus and Grafana.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="feature">
                <img
                  src={features__protocols}
                  alt="Traefik Mesh - Service mesh HTTP and TCP compatible"
                />
                <h3>Protocols</h3>
                <ul>
                  <li>
                    <p>
                      <strong>HTTP layer:</strong> HTTPS, HTTP/2, native gRPC,
                      Websockets.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>TCP layer:</strong> Route raw TCP connection for
                      any applicative protocol other than HTTP.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="feature">
                <img
                  src={features__traffic}
                  alt="Traefik Mesh - Service mesh with load balancing, retries, failovers, circuit breakers and rate limits"
                />
                <h3>Traffic Management</h3>
                <ul>
                  <li>
                    <p>
                      <strong>Load balancing:</strong> From weighted round-robin
                      to canary deployments.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Retries &amp; failovers:</strong> Don’t let a
                      network hiccup affect your customers.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Circuit breakers &amp; rate limits:</strong>{" "}
                      Always answers valuable content.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="feature">
                <img
                  src={features__security}
                  alt="Traefik Mesh - Service mesh SMI compliant"
                />
                <h3>Security &amp; Safety</h3>
                <ul>
                  <li>
                    <p>
                      <strong>SMI Compliance:</strong> Supports the Service Mesh
                      Interface (SMI) specification.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Access controls:</strong> Control who has access
                      to which resource.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="feature">
                <img
                  src={features__installation}
                  alt="Traefik Mesh - Service mesh easy to install and configure"
                />
                <h3>Easy installation and configuration</h3>
                <ul>
                  <li>
                    <p>
                      <strong>One-line Installation:</strong> Get all features
                      from startup with a single command line.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>Non-invasive:</strong> Traefik Mesh does not use
                      any sidecar container and does not need any pod-injection.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Integrations */}
        <section className="maesh-integrations">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Run it everywhere that Kubernetes does!</h2>
            <p className="sub-title">
              Traefik Mesh integrates into Kubernetes natively. Use it with your
              current ingress controller, and test it out. Use the Traefik Mesh
              services as your ingress endpoints, and see what Traefik Mesh can
              do for you!
            </p>
            <div className="logos">
              <div className="logo">
                <img src={integration__kubernetes} alt="kubernetes" />
              </div>

              <div className="logo">
                <StaticImage
                  src="../images/maesh/integrations/google-logo/google.jpg"
                  alt="google kubernetes engine"
                  layout="fixed"
                  width={152}
                  height={88}
                  placeholder="blurred"
                />
              </div>

              <div className="logo">
                <StaticImage
                  src="../images/maesh/integrations/aks/aks-logo.png"
                  alt="AKS"
                  layout="fixed"
                  width={153}
                  height={88}
                  placeholder="blurred"
                />
              </div>

              <div className="logo">
                <StaticImage
                  src="../images/maesh/integrations/k-3-s/k-3-s-logo.jpg"
                  alt="k3s"
                  layout="fixed"
                  width={168}
                  height={64}
                  placeholder="blurred"
                />
              </div>

              <div className="logo">
                <StaticImage
                  src="../images/maesh/integrations/amazon/amazon.jpg"
                  alt="amazon eks"
                  layout="fixed"
                  width={168}
                  height={45}
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </section>

        {/* CTA */}
        <section className="maesh-next-steps" id="maesh-cta">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Ready to start your Traefik Mesh journey?</h2>
            <p className="sub-title">
              Traefik Mesh is installable via Helm, and is extremely easy to
              customize, or to package into other solutions that are able to
              deploy charts.
            </p>
            <p className="sub-title">
              Install Traefik Mesh and test how it performs on your
              environments, without having to commit or submit to invasive
              pod-injection conditions!
            </p>

            <div className="cta-link-cards">
              <div className="cta-link-card">
                <div className="link">
                  <a href="https://github.com/traefik/mesh">
                    GitHub Project &rarr;
                  </a>
                </div>
                <div className="image image--align-bottom">
                  <img src={cta__path} />
                </div>
              </div>

              <div className="cta-link-card">
                <div className="link">
                  <a href="https://docs.mae.sh/">Documentation &rarr;</a>
                </div>
                <div className="image">
                  <img src={cta__documentation} />
                </div>
              </div>

              <div className="cta-link-card">
                <div className="link">
                  <a href="https://community.traefik.io/c/traefik-mesh/15">
                    Community Forum &rarr;
                  </a>
                </div>
                <div className="image">
                  <img src={cta__community} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default MaeshPage

export const query = graphql`
  {
    current_page: ghostPage(slug: { eq: "maesh" }) {
      ...PageMetaFields
    }
  }
`
